.horizontal-list-container {
    width: 100%; /* Or specify a specific width */
   
  }
  
  .horizontal-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex; /* Use Flexbox */
    justify-content: flex-start; /* Justify to the left */
    flex-wrap: wrap; /* Prevent wrapping */
    font-size: 0.83rem;
  }
  
  .horizontal-list li {
    /* Add styling for list items */
    margin-right: 32px;
     /* Adjust as needed for spacing between items */
     margin-bottom: 10px;
    list-style: circle;
    color: rgb(197, 197, 197);
    cursor: pointer;

  }
  .horizontal-list li:hover {
    /* Add styling for list items */
    margin-right: 32px; /* Adjust as needed for spacing between items */
    list-style: disc;
    color: #fd7e14;

  }
  