.sticky-navbar {
  background: #F36F2E !important;
  
  padding-left:0.1% !important;
  padding-right:0.1% !important;
  width: 100% !important;
  overflow-y: auto !important;
}
.navlogo{
  margin-left:-0.5rem !important;
} 
a{
  text-decoration: none !important;
}
.nav-link{
  font-size:0.85rem !important;
}



.cardLink{
  border-radius: 8px;
border: 1px solid #F14810;
box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.06);
display: flex;
height: auto;
padding-top:1.5rem;
padding-bottom:1.5rem;
padding-left:1rem;
padding-right:1rem;
gap: 173px;
}
.navbar .nav-link{
  font-size:0.95rem !important;
  font-family: 'Jost', sans-serif;
  color: #FFFFFF !important;
  text-decoration: none !important;
  margin-left:0rem;
  margin-right:0rem;
  margin-top:0.4rem;
 
}

.button{
  margin-top:1rem;
  margin-left:0rem;
}
.Modalbody{
  background: rgba(34, 34, 34, 0.65);
}
.modal-dialog{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.modal-content{
  width: 98% !important;

}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: 1px !important;
  position: relative;
  list-style: none;
  font-size:0.85rem !important;
  padding: 6px 16px !important;
  cursor: pointer;
}

.react-tabs__tab-list {
  border-bottom: none !important;
  margin: 0 0 0px;
  padding: 0px;
}

.react-tabs__tab--selected {
  background: #2D2A2A !important;
  border-color: #aaa;
  color: #FFFFFF !important;
  border-radius: 19.05px 19.05px 19.05px 19.05px !important  ;
}
.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e);
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: none !important;
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.selectbox{
  padding:0.8rem;
  border:1px solid #C6C6C6;
  background-color: #FFFFFF;
  border-radius:5px;
  box-shadow: 4px 2px 5px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 4px 2px 5px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 4px 2px 5px 0px rgba(0,0,0,0.1);
}
.activeNavLink {
  color: black !important; /* Set the color for the active NavLink to black */
}


@media (min-width: 576px) { 
  
  .sticky-navbar{
    padding-left:0% !important;
    padding-right:0% !important;
  }

 }


@media (min-width: 768px) { 
  .sticky-navbar{
    padding-left:2% !important;
    padding-right:2% !important;
  }
  .navbar-nav .nav-link{
    font-size:0.8rem !important;
    font-family: 'Jost', sans-serif;
    color: #ffffff !important;
    text-decoration: none !important;
    margin-left:0.6rem;
    margin-right:0.6rem; 
  }
 
  
  .navlogo{
    margin-left:0rem !important;
  }
  .button{
    margin-top:0rem;
    margin-left:1rem;
  }
  .modal-content{
    width: 90% !important;
  
  }
  button .nav-link{
    background-color: #DCDCDC !important;
    border-radius:36px !important;
    width:100%;
    height:2.5rem !important;
    padding-bottom: 0.2rem !important;
    padding-top: 0rem !important;
    padding-right:0rem !important;
    padding-left:0rem !important;
   
  }
  
  
  

 }


@media (min-width: 992px) { 
  .sticky-navbar{
    padding-left:7% !important;
    padding-right:7% !important;
   
  }
  .offcanvas{
    width:29% !important;
  }

 }


@media (min-width: 1200px) { 
  .sticky-navbar{
    padding-left:8% !important;
    padding-right:8% !important;
  }

 }


@media (min-width: 1400px) { 
  .sticky-navbar{
    padding-left:14% !important;
    padding-right:14% !important;
  }

 }


 @media (min-width: 1600px) { 
  .sticky-navbar{
    padding-left:20% !important;
    padding-right:20% !important;
  }

 }