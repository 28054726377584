@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&family=Redressed&display=swap");

.dashboardContainer {
    background-color: #EFF1F7;
    height: 100vh;
    padding: 0;
    overflow: hidden !important;
    padding-right:1% !important;
    padding-left:1% !important;
    width: 100%;
    overflow-x: hidden !important;
}

.sidebar {
    border-right: 1px solid #dbdbdb;
    background-color: #000000 !important;
    box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.05);
    /* padding-top: 1rem; */
    /* top: -2% !important; */
    height: 100vh !important;
    position: relative;
    z-index: 10;
}
.css-dip3t8{
    background-image: url(../../client/assets/formsidebg.png) !important;
    background-size: cover;
    background-repeat: no-repeat;
}

.navbar-brand-space {
    display: inline !important;
}

.menu {
    margin-top: 40px;
}

/* the sidebar menu links */
.css-a7ufv>.ps-menu-button {
    padding-right: 0 !important;
    /* padding-left: 10px !important; */
}


.css-1wvake5 {
    width: 14rem !important;
    /* width: 230px !important; */
    max-width: 230px !important;
}

.css-1d7b5ut {
    display: flex;
    flex-direction: column;
}

.ps-active {
    color: #f5f5f5 !important;
}

.bodycontainer {
    padding-top: 0.6rem !important;
    padding-left: 0.9rem;
    padding-right: 0.9rem;
    width: 100% !important;
    height: 100vh;
    overflow-y: scroll;
}

/* Target Webkit (Chrome, Safari) */
::-webkit-scrollbar {
    width: 1px;
    /* Adjust the width as needed */
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Background color of the scrollbar track */
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    /* Color of the scrollbar handle */
}

/* Hover state on handle */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.box{
    background: url(../assets/cardbg.svg) #FFFFFF;
    background-size: initial;
    background-position: bottom right;
    background-repeat: no-repeat !important;
}
.selectusertype{
    width:60% !important;
    background-color: #fefefe !important;
    border-radius:36px !important;
    /* overflow:   hidden !important; */
    height:2.5rem !important;
    padding-bottom: 0.2rem !important;
    padding-top: 0rem !important;
    padding-right:0.3rem !important;
    padding-left:0.3rem !important;
  }
  .offcanvas{
    width: 100% !important;
  }
  .custom-datepicker .react-datepicker-wrapper,
.custom-datepicker .react-datepicker__input-container {
  display: inline-block;
}

.custom-datepicker .react-datepicker__input-container input {
  height: 10px !important; /* Adjust the height as needed */
}
.blogpreview{
    width:100% !important;
    padding-top:2rem;
    padding-bottom:5rem;
}
.btn-light {
    --bs-btn-color: #000;
    --bs-btn-bg: none !important;
    --bs-btn-border-color: #dee2e6 !important;
    --bs-btn-hover-color: none !important;
    --bs-btn-hover-bg: none !important;
    --bs-btn-hover-border-color: #c6c7c8;
    --bs-btn-focus-shadow-rgb: 211, 212, 213;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: none !important;
    --bs-btn-active-border-color: #babbbc;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #f8f9fa;
    --bs-btn-disabled-border-color: #f8f9fa;
}
.btn {
    --bs-btn-padding-x: 0.75rem;
    --bs-btn-padding-y: 0.375rem;
    --bs-btn-font-family:"jost" !important ;
    --bs-btn-font-size: 0.1rem;
    --bs-btn-font-weight: 300 !important;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: var(--bs-body-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-width: var(--bs-border-width);
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: var(--bs-border-radius);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

  


@media (min-width: 768px) {
   
    .dashboardContainer {
        background-color: #EFF1F7;
        height: 100vh;
        padding: 0;
        overflow: hidden !important;
        padding-right:5% !important;
        padding-left:5% !important;
        width: 100%;
    }
    .selectusertype{
        width:20% !important;
        background-color: #fefefe !important;
        border-radius:36px !important;
        /* overflow:   hidden !important; */
        height:2.5rem !important;
        padding-bottom: 0.2rem !important;
        padding-top: 0rem !important;
        padding-right:0.3rem !important;
        padding-left:0.3rem !important;
      }
      .offcanvas{
        width: 40% !important;
      }
      .blogpreview{
        width:60% !important;
        padding-top:2rem;
        padding-bottom:5rem;
    }
    
    
}