.institution-dashboard {
    overflow-y: auto;
}
.form-check-input:checked {
    background-color: #F36F2E !important;
    border-color: #F36F2E !important;
}
.custom-toggle::after {
    display: none !important; /* Hide the dropdown arrow */
  }


  .dropdown-menu {
    --bs-dropdown-zindex: 1000;
    --bs-dropdown-min-width: 7rem !important;
    --bs-dropdown-padding-x: 0;
    --bs-dropdown-padding-y: 0.5rem;
    --bs-dropdown-spacer: 0.125rem;
    --bs-dropdown-font-size: 1rem;
    --bs-dropdown-color: var(--bs-body-color);
    --bs-dropdown-bg: var(--bs-body-bg);
    --bs-dropdown-border-color: var(--bs-border-color-translucent);
    --bs-dropdown-border-radius: var(--bs-border-radius);
    --bs-dropdown-border-width: var(--bs-border-width);
    --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
    --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
    --bs-dropdown-divider-margin-y: 0.5rem;
    --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --bs-dropdown-link-color: var(--bs-body-color);
    --bs-dropdown-link-hover-color: var(--bs-body-color);
    --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
    --bs-dropdown-link-active-color: #fff;
    --bs-dropdown-link-active-bg: #F36F2E !important;
    --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
    --bs-dropdown-item-padding-x: 1rem;
    --bs-dropdown-item-padding-y: 0.25rem;
    --bs-dropdown-header-color: #6c757d;
    --bs-dropdown-header-padding-x: 1rem;
    --bs-dropdown-header-padding-y: 0.5rem;
    position: absolute;
    z-index: var(--bs-dropdown-zindex);
    display: none;
    min-width: var(--bs-dropdown-min-width);
    padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
    margin: 0;
    font-size: var(--bs-dropdown-font-size);
    color: var(--bs-dropdown-color);
   
}

.overview {
    display: flex;
    gap: 15px;
    margin-top: 10px;
}

.overview-box {
    padding: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-grow: 1;
    flex-shrink: 0;
    box-shadow: 0px 0px 5px 0 #b2b3b3;
    border: 1px solid #b8b8b8;
}

.term-overview-box {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0px solid grey;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.term-progress-box {
    flex-basis: 35%;
    background-color: #263238;
    color: white;
    padding: 20px;
    border-radius: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.overview .box-grid {
    flex-basis: 55%;
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
}

.overview-box h4 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
}

.overview-box h3 {
    margin-bottom: 0;
}

.overview-box p {
    font-size: 14px;
    margin-bottom: 0;
}

.overview-box span {
    font-size: 12px;
    font-weight: 200;
}

.progressDiplay {
    width: 90px;
    height: 90px;
    border-radius: 100%;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.progressDiplay > div {
    height: 80%;
    width: 80%;
    background-color: #263238;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.report-overview {
    display: flex;
    align-items: stretch;
    gap: 20px;
    margin-top: 20px;
}

.financial-report {
    flex-basis: 60%;
}

.financial-report img {
    width: 100%;
    height: 100%;
}

.report-overview .right {
    flex-basis: 40%;

}

.report-box {
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
    /* padding-bottom: 40px; */
}

.report-box .vector {
    position: absolute;
    top: 0;
    right: 0;
}

.report-box h4, .report-box p {
    position: relative;
    z-index: 3;
}

.report-box h4 {
    margin-bottom: 0;
}




@media (max-width: 1000px) {
    .report-overview {
        flex-direction: column;
    }
    .institution-dashboard .overview {
        flex-direction: column;
    }
}

@media (max-width: 600px) {
    .overview-box {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}