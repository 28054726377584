.contactus{
    height:100vh;
}
.formside{
    background: url(../../assets/formsidebg.png);
    background-size: cover !important;
    background-repeat:no-repeat;
    height:auto !important;
    min-height: auto !important;
    padding-bottom: 3rem;
    width:100%;
    bottom:0;
    margin-top:5rem !important;
    padding-top: 2rem;
    padding-left:4%;
    padding-right:3%;
    display: inline !important;
    
}
.navlogodisplay{
    display:inline !important;
    margin-bottom:2rem;
}
.formmain{
    padding-top: 2rem;
    padding-left:5% ;
    padding-right:5% ;
    
}
.formcontrol {
    border-radius: 4.42px;
    border: 0.9px solid var(--input-field-stoke, #DDD) !important;
    background: #EDEDED !important;
    box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.02);
}


.formcontrol::placeholder {
    font-size: 0.85rem; /* Adjust the font size as needed */
  }

  .formlabel{
    font-size:0.85rem;
  }

  /* Reduce the focus border and add a box-shadow effect */
  .formcontrol:focus {
    outline: none;
    box-shadow: 0 0 0.2px 0.2px rgba(80, 80, 80, 0.2) !important;
  }
  .logoss{
    display: none !important;
}
.otherContent{
    margin-top: 5vh !important;
    display: flex;
    flex-direction:column;
    gap: 1rem;
}



@media (min-width: 576px) { 

 }


@media (min-width: 768px) { 
    .navlogodisplay{
        display:none !important;
    }
    .formmain{
        padding-top: 2rem;
        padding-left:5% ;
        padding-right:5%;
        
    }
    .formside{
        background: url(../../assets/formsidebg.png);
        background-size: cover !important;
        height:100dvh;
        min-height:100dvh;
        margin-top:0rem !important;
        padding-left:7% ;
        padding-right:7%;
        display: inline !important;
        
    }
    .otherContent{
        margin-top: 50vh !important;
        display: flex;
        flex-direction:column;
        gap: 1rem;
    }
    .logoss{
        display: inline !important;
    }
    

 }

 @media (min-width: 850px) { 
    .formmain{
        padding-top: 2rem;
        padding-left:5% ;
        padding-right:5% ;
        
    }
    .formside{
        padding-left:8% ;
        padding-right:8%;
        
    }
 }


@media (min-width: 992px) { 
    .formmain{
        padding-top: 2rem;
        padding-left:12% ;
        padding-right:7% ;
        
    }
    .formside{
        padding-left:9% ;
        padding-right:8%;
        
    }
 }


@media (min-width: 1200px) { 
    .formmain{
        padding-top: 2rem;
        padding-left:8%;
        padding-right:8%;
        
    }
    .formside{
        padding-left:9%;
        padding-right:8%;
        
    }

 }


@media (min-width: 1400px) { 
    .formmain{
        padding-top: 2rem;
        padding-left:14%;
        padding-right:14%;
        
    }
    .formside{
        padding-left:19% ;
        padding-right:14%;
        
    }

 }