.SafetyHero{
    width:100%;
}
.SafetyHeader{
    background-color: #F36F2E;
    background-image:url(../../assets/WorldMap2.svg);
    color: aliceblue;
    padding-top: 2rem;
    padding-bottom:2rem;
    padding-left:3% !important;
    padding-right:3% !important;
}
.belowsection{
    padding-left:5% !important;
    padding-right:5% !important;
    margin-top: 0rem;
}
.belowsectiontextarea{
    padding-top:0rem;
}
.safetyexperience{
    margin-top: 7rem !important;
}
.drivesafely{
    margin-top: 7rem !important;
}




@media (min-width: 576px) {  }


@media (min-width: 768px) { 
    .SafetyHeader{
        background-color: #F36F2E;
        color: aliceblue;
        padding-top: 2rem;
        padding-bottom:2rem;
        padding-left:9% !important;
        padding-right:9% !important;
    }
    .belowsection{
        padding-left:9% !important;
        padding-right:9% !important;
        margin-top: 4rem;
    }
    .belowsectiontextarea{
        padding-top:0rem;
    }
    .safetyexperience{
        margin-top: 7rem !important;
    }
    .drivesafely{
        margin-top: 7rem !important;
    }
    
    
}


@media (min-width: 992px) { 
    .SafetyHeader{
        background-color: #F36F2E;
        color: aliceblue;
        padding-top: 2rem;
        padding-bottom:2rem;
        padding-left:10% !important;
        padding-right:10% !important;
    }
    .belowsection{
        padding-left:10% !important;
        padding-right:10% !important;
        margin-top: 4rem;
    }
    .belowsectiontextarea{
        padding-top:0rem;
    }
    .safetyexperience{
        margin-top: 7rem !important;
    }
    .drivesafely{
        margin-top: 7rem !important;
    }
    
    
 }


@media (min-width: 1200px) { 
    .SafetyHeader{
        background-color: #F36F2E;
        color: aliceblue;
        padding-top: 2rem;
        padding-bottom:2rem;
        padding-left:9% !important;
        padding-right:9% !important;
    }
    .belowsection{
        padding-left:9% !important;
        padding-right:9% !important;
        margin-top: 4rem;
    }
    .belowsectiontextarea{
        padding-top:0rem;
    }
    .safetyexperience{
        margin-top: 7rem !important;
    }
    .drivesafely{
        margin-top: 7rem !important;
    }
    
    
 }


@media (min-width: 1400px) { 
    .SafetyHeader{
        background-color: #F36F2E;
        color: aliceblue;
        padding-top: 2rem;
        padding-bottom:2rem;
        padding-left:14% !important;
        padding-right:14% !important;
    }
    .belowsection{
        padding-left:14% !important;
        padding-right:14% !important;
        margin-top: 4rem;
    }
    .belowsectiontextarea{
        padding-top:0rem;
    }
    .safetyexperience{
        margin-top: 7rem !important;
    }
    .drivesafely{
        margin-top: 7rem !important;
    }
    
    
 }

 @media (min-width: 1600px) { 
    .SafetyHeader{
        background-color: #F36F2E;
        color: aliceblue;
        padding-top: 2rem;
        padding-bottom:2rem;
        padding-left:20% !important;
        padding-right:20% !important;
    }
    .belowsection{
        padding-left:20% !important;
        padding-right:20% !important;
        margin-top: 4rem;
    }
    .belowsectiontextarea{
        padding-top:0rem;
    }
    .safetyexperience{
        margin-top: 7rem !important;
    }
    .drivesafely{
        margin-top: 7rem !important;
    }
    
    
 }