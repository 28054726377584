@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&family=Redressed&display=swap");

.herosection {
  background: linear-gradient(180deg, #F36F2E 42.45%, #FFF 42.53%) !important; 
  height: auto;
  margin-bottom: 7.6rem;
  width: 100% !important;
  overflow-y: hidden !important;
  padding-left:3% !important;
  padding-right:3% !important;
 
}

.containersection{
  width: 100%;
  height: auto;
  margin-top:2rem;
  background-color: #313434 !important; /* Set your background color */
  clip-path: polygon( 0.051% 7.117%,0.051% 7.117%,0.121% 5.969%,0.379% 4.878%,0.811% 3.859%,1.4% 2.926%,2.131% 2.095%,2.988% 1.381%,3.956% 0.8%,5.02% 0.366%,6.163% 0.094%,7.37% 0%,92.622% 0%,92.622% 0%,93.83% 0.094%,94.973% 0.366%,96.037% 0.801%,97.006% 1.383%,97.864% 2.098%,98.594% 2.93%,99.183% 3.863%,99.614% 4.884%,99.872% 5.976%,99.94% 7.125%,97.633% 93.229%,97.633% 93.229%,97.511% 94.334%,97.214% 95.38%,96.758% 96.353%,96.157% 97.24%,95.425% 98.027%,94.577% 98.701%,93.628% 99.249%,92.591% 99.657%,91.482% 99.912%,90.315% 100%,9.574% 100%,9.574% 100%,8.406% 99.912%,7.296% 99.657%,6.259% 99.248%,5.309% 98.7%,4.461% 98.024%,3.729% 97.236%,3.128% 96.348%,2.673% 95.374%,2.377% 94.327%,2.256% 93.221%,0.051% 7.117% ) !important;
}
.textsection{
  padding-top:4rem;
  padding-bottom:4rem;
  padding-left:8% !important;
  padding-right:8% !important;
}
.imagesection{
  background-image:url(../../assets/WorldMap.svg);
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 10%; /* 4:3 aspect ratio (adjust as needed) */
  overflow: hidden;
  
}
.mobilephone{
    position: absolute;
    top: 0%;
    left: 19%;
    width: 295px;
    height: 100%;
    object-fit: cover; /* or "contain" depending on your needs */
    object-position: top center; /* Adjust as needed */
    display: none !important;
}

.downloadbtn{
  display:flex;
  flex-direction: column !important;
  margin-left:0rem;
  gap:0.5rem;

}
.buttondownload{
  width:100% !important;
  height: 2.5rem !important;
}
.herotitle{
  font-size:1.5rem;
}
.introsection{
  width:95%;
  height:auto;
  padding-left:5% !important;
  padding-right:5% !important;
  margin-bottom:4rem;
}
.introinnersection{
  background:url(../../assets/mapbg.png), #F5F6F7;
  background-size: contain;
  padding-top:5rem;
  padding-left:1%;
  padding-right:1%;
  padding-bottom:5rem;
  border-radius:35px;
 
}
.introheader{
  font-size:2.5rem;
  font-family: "jost";
}
.introparagraph{
  font-size:0.8rem;
  width:95%;
  line-height:195%;
}
.readmorebtn{
  background-color: transparent !important;
  color: #313434 !important;
  border-color: #313434 !important;
  border-radius: 35px !important;
}
.productsection{
  margin-top:5rem;
  height: auto;
  margin-bottom:0rem;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px !important;
  --bs-nav-tabs-border-color:  rgb(207, 47, 47) !important;
  --bs-nav-tabs-bg:  black !important;
  --bs-nav-tabs-link-border-radius: 30px  !important;
  --bs-nav-tabs-link-hover-border-color:transparent !important;
  --bs-nav-tabs-link-active-color: #ffffff !important;
  --bs-nav-tabs-link-bg: #2D2D2D !important;
  --bs-nav-tabs-link-active-bg: #2D2A2A !important;
  --bs-nav-tabs-link-active-border-color: black !important;
  border-bottom: none !important;
  
}
.producttab{
  background-color: #DCDCDC !important;
  border-radius:36px !important;
  /* overflow:   hidden !important; */
  width:80%;
  height:2.5rem !important;
  padding-bottom: 0.2rem !important;
  padding-top: 0rem !important;
  padding-right:5% !important;
  padding-left:5% !important;
 
}

button.nav-link{
  border-radius:36px !important;
  background-color: #DCDCDC ;
  margin-top:0.2rem !important;
  height:2.1rem !important;
  color: #625C5C;
}
button.nav-link:focus:active{
  border-radius:36px !important;
  background-color: #2D2A2A ;
  margin-top:0.2rem !important;
  height:2.1rem !important;
  color: #FFFFFF ;
}
button.nav-link:hover{
  color: #625C5C;
}


.productsection{
  width:100%;
  padding-left:5%;
  padding-right:5%;
  display: grid;
  place-items: center;
  padding-top:4rem !important;
  padding-bottom:0rem !important;
}
.productheader{
  color:#1D2221 ;
}
.producttext{
  font-size:0.85rem;
  color:#565656;
  padding-left:3% !important;
  padding-right:3% !important;
  font-family: 'jost';
}
.shipperimage{
  margin-left: -1.5rem;
  display:none !important;
}
.productsectionrow{
  padding-left:1% !important;
  padding-right:1% !important;
  margin-top: -2rem !important;
}
.categorycontainer{
  width:100%;
  margin-top:7rem;
  padding-left:5% !important;
  padding-right:5% !important;
}
.categorytext{
  font-size:0.85rem;
  color:#565656;
  padding-left:4% !important;
  padding-right:4% !important;
  font-family: 'jost';
}
.carousel-container {
  position: relative;
  margin-bottom:4rem;
}



.carousel-control {
  position: absolute;
  bottom: 50px; 
  top:90%;
}


.carousel-control-next-icon {
  background-image: url(../../assets/carouselrighticon.svg) !important;
  width:3rem !important;
  height:3rem !important;
}

.carousel-control-prev-icon {
  background-image: url(../../assets/carousellefticon.svg) !important;
  width:3rem !important;
  height:3rem !important;
}
.figure-container {
  position: relative;
}

.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(287deg, #2E2D2D 1.95%, rgba(46, 45, 45, 0.00) 90.53%);
  padding: 10px;
  padding-left:12px;
  width: 100%; /* Optional: Set the width of the caption */
  text-align: left; /* Optional: Center the text */
  color: #FFFFFF;
}
.captionheader{
  font-size:1rem;
  font-family: "jost";
  color: #FFFFFF;

}
.captiondescription{
  font-size:0.71rem;
  font-family: "jost";
  color: #EAEAEA;
  

}



.downloadtab{
  background-color: #DCDCDC !important;
  border-radius:36px !important;
  /* overflow:   hidden !important; */
  width:80%;
  height:2.5rem !important;
  padding-bottom: 0.2rem !important;
  padding-top: 0rem !important;
  padding-right:0.3rem !important;
  padding-left:0.3rem !important;
 
}
.circleradiusdivx1 {
  border-radius: 29px;
  background: #EAEAEA;
  display: flex;
  padding: 5px 29px 5px 29px;
  justify-content: center;
  align-items: center;
  width: 6.2rem;
  margin-bottom: 0.5rem;
  font-size: 0.86rem;
  font-family: "Jost", sans-serif;
  color: #f14810;
  font-weight: 600;
}

/* accordion */
.accordion{
  --bs-accordion-btn-focus-box-shadow: none !important;
  --bs-accordion-btn-active-icon:url(../../assets/nonactiveaccordion.svg) !important;
  --bs-accordion-btn-icon:url(../../assets/nonactiveaccordion.svg) !important;
  --bs-accordion-btn-icon-transform:rotate(180deg) !important;
  --bs-accordion-btn-icon-width: 0.8rem !important;
}
.accordion-btn-icon{
  margin-top:0.5rem !important;
}

.accordion-button {
  border-radius: 20px !important;
  border: 0.8px solid #ff8156 !important;
  background: #fff8f5 !important;
  padding:0.8rem !important;
  font-size:0.86rem !important;
  font-weight: 500 !important;
  color: #5E5E5E !important;
  font-family: "Jost", sans-serif;

}
.accordion-body {
  color: #4C4B4B !important; 
  background-color: #fff9f7;
  border-radius: 10px !important;
  margin-top:0.5rem;
  font-size:0.8rem !important;
  line-height: 205.5%; /* 36.99px */
  font-family: "Jost", sans-serif;
}

.accordion-button:focus{
  border-radius: 20px !important;
  border: 1.5px solid #f14810 !important;
  background: #fff8f5 !important;
}
.accordion-button:not(.collapsed){
  border-radius: 20px !important;
  border: 2px solid #f14810 !important;
  background: #fff8f5 !important;
  color: #5E5E5E !important;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 10px !important;

}
.accordion-item{
  margin-bottom:1rem;
}

/* frequently asked question */
.FAQ {
  padding-left: 5% !important;
  padding-right: 5% !important;
  padding-top: 4rem;
  margin-top: 3rem;
}
.mainaccordion{
  width:100%;
}
/* circular card */
.circular-card {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  border: 2px solid #ffffff;
  position: relative;
}

.circular-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.circular-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This property makes the image cover the circular div */
}


/* stack of card */

.card-stack {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-stack > div {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  border: 2px solid #ffffff;
  overflow: hidden;
  position: absolute;
}

.card-stack > div:first-child {
  z-index: 3;
  transform: translateX(-50%);
  left: 51%;
}

.card-stack > div:nth-child(2) {
  z-index: 2;
  transform: translateX(-100%);
  left: 49%;
}

.card-stack > div:last-child {
  z-index: 1;
  transform: translateX(0);
  left: 53%;
}

.middle-card {
  position: relative;
  width: 4.5rem; /* Adjust the size of the middle card as needed */
  height: 4.5rem;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a shadow effect to the middle card */
}
.Contactus{
  margin-top:5rem;
  margin-bottom:5rem;
  background-color: #F9FAFB;
  padding-top: 5rem;
  padding-bottom:3rem;
  width:95% !important;
  border-radius:0.5rem;
}
.Bottomnav{
  margin-top:9rem;
  padding-left:6% !important;
  padding-right:6% !important;
  padding-top:3rem;
  padding-bottom:3rem;
}
.bottomparagraph{
  font-size:0.8rem;
  font-family: 'Jost', sans-serif;
  color: #5C5571;
  margin-top: 0.5rem;
  line-height:21px;
}
.colbutton{
  margin-right:0px !important;
}
.navlinkingclass{
  float:left !important;
}
.navlinkingclass li{
  margin-bottom:0.6rem;
  font-family: 'Jost', sans-serif;

}
.newsletterparagraph{
  font-family: 'Jost', sans-serif;
  font-size:0.8rem;
  color: #5C5571;
}
.newsletterhead{
  font-family: 'Jost', sans-serif;
  font-size:0.9rem;
  color: #5C5571;
  margin-top:2rem;
}
.newsletterformbody{
  width:100%;
  float:left;
  margin-top:2rem;
}
.newsletterformbody .form-control{
  border:none !important
}
.form-control:focus {
  outline: none !important;
  box-shadow: none !important; /* Optional: Remove box-shadow for more styling consistency */
}

.react-multiple-carousel__arrow{
  background-color: #bebdbd !important;
  min-width: 34px !important;
  min-height:34px !important;
}
.categoryheader{
  font-size: 2.3rem;
  font-weight: bold;
}

.featurescontainer{
  width:100%;
  padding-left:5% !important;
  padding-right:5% !important;
  margin-top: 5rem;
}
.featureheader{
  font-size:1.1rem;
  font-family: 'jost';
  color: #F36F2E;
  font-weight: bold;
}
.featuredescription{
  font-size:0.8rem;
  font-family: 'jost';
  color:#5C5571;
  width:85%;

}
.InstantRequest{
  background: url(../../assets/instantrequestbg.svg);
  background-position: right bottom;
  background-repeat: no-repeat;
}
.ScheduleRequest{
  background: url(../../assets/schedulerequest.svg);
  background-position: right bottom;
  background-repeat: no-repeat;
}
.Transporter{
  background: url(../../assets/transporter.svg);
  background-position: right bottom;
  background-repeat: no-repeat;
}
.Wallet{
  background: url(../../assets/wallet.svg);
  background-position: right bottom;
  background-repeat: no-repeat;
}
.Tracking{
  background: url(../../assets/tracking.svg);
  background-position: right bottom;
  background-repeat: no-repeat;
}
.BuyAirtime{
  background: url(../../assets/buyairtime.svg);
  background-position: right bottom;
  background-repeat: no-repeat;
}
.NewsContainer{
  width:100%;
  margin-top: 5rem;
  padding-left:5% !important;
  padding-right:5% !important;
}
.Newsheader{
  font-size:2rem;
  font-family: 'jost';
  font-weight: bold;
}
.downloadContainerMain{
  margin-top:10rem;
  padding-left:5% !important;
  padding-right:5% !important;

}
.downloadContainer{
  background: url(../../assets/downloadbg.svg) #1D2221;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  padding-top:3rem;
  padding-bottom:3rem;
  border-radius: 19px;
  width: 100%;

}
.downloadheader{
  color: #EAEAEA;
  font-size:2rem;
  font-family: 'jost';
}
.downloadtext{
  color: #DCDCDC;
  font-size:0.8rem;
  font-family: 'jost';
}
.buttoncontainer{
  display:flex;
  flex-direction: column;
  gap:5px;
  justify-content:center;
  align-items:center;
}
.downloadappContainer{
  padding-left:1%;
  padding-right:1%;
}
.contacttext{
  width:95%;
}
.waitlist-btn{
  width:100% !important;
}
.animated-category{
  width:100% !important;

}
.services-container{
  display:flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
  gap:2rem;
  margin-top:9rem;

}
.service-title{
  text-align: start;
  justify-content: start;
  align-items: start;
  width:100%;
 
}
.figurebase{
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}
.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex !important;
  flex-wrap: nowrap !important;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  width: 100% !important; /* Set the width */
  margin: 0 auto; /* Center the nav */
  overflow-x: auto !important; /* Allow scrolling */
  overflow-y: hidden !important;
  scrollbar-width: none; /* For Firefox */
}

.nav::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}


.downloadtab {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex !important;
  flex-wrap: nowrap !important;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  width: 100% !important; /* Set the width */
  margin: 0 auto; /* Center the nav */
  overflow-x: auto !important; /* Allow scrolling */
  overflow-y: hidden !important;
  scrollbar-width: none; /* For Firefox */
}

.nav::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}

.services-container{
  padding-left:4% !important;
  padding-right:4% !important;
}
button{
  flex-wrap: nowrap !important;
}
/* styles.css */
.hide-on-mobile {
  display: none;
}

@media (min-width: 768px) {
  .hide-on-mobile {
      display: block;
  }
}










@media (min-width: 321px) {
  .herosection {
    background: linear-gradient(180deg, #F36F2E 42.45%, #FFF 42.53%) !important; 
    height: auto;
    width: 100% !important;
    overflow-y: hidden !important;
    padding-left:4% !important;
    padding-right:4% !important;
   
  }
  
  .containersection{
    width: 100%;
    height: auto;
    background-color: #313434; /* Set your background color */
   
  }
  .textsection{
  
    padding-left:8% !important;
    padding-right:8% !important;
  }
  .imagesection{
    background-image:url(../../assets/WorldMap.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 60%; /* 4:3 aspect ratio (adjust as needed) */
    overflow: hidden;
    
  }
  .mobilephone{
      position: absolute;
      top: 0%;
      left: 14%;
      width: 245px;
      height: 100%;
      object-fit: cover; /* or "contain" depending on your needs */
      object-position: top center; /* Adjust as needed */
      display: inline !important;
  }

  .downloadbtn{
    display:flex;
    flex-direction: row !important;
    margin-left:0rem;
    gap:0.5rem;
   
  }
  .buttondownload{
    width:50% !important;
  }
  .herotitle{
    font-size:1.6rem;
  }
  
}




@media (min-width: 380px) {
  .herosection {
    background: linear-gradient(180deg, #F36F2E 42.45%, #FFF 42.53%) !important; 
    height: auto;
    width: 100% !important;
    overflow-y: hidden !important;
    padding-left:4% !important;
    padding-right:4% !important;
  
  }
  
  .containersection{
    width: 100%;
    height: auto;
    background-color: #313434; /* Set your background color */
   
  }
  .textsection{
  
    padding-left:8% !important;
    padding-right:8% !important;
  }
  .imagesection{
    background-image:url(../../assets/WorldMap.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 60%; /* 4:3 aspect ratio (adjust as needed) */
    overflow: hidden;
    
  }
  .mobilephone{
      position: absolute;
      top: 0%;
      left: 19%;
      width: 245px;
      height: 100%;
      object-fit: cover; /* or "contain" depending on your needs */
      object-position: top center; /* Adjust as needed */
  }

  .downloadbtn{
    display:flex;
    flex-direction: row;
    margin-left:0rem;
    gap:0.5rem;
   
  }
  .buttondownload{
    width:50% !important;
  }
  .herotitle{
    font-size:2.0rem;
  }
  .producttab{
  background-color: #DCDCDC !important;
  border-radius:36px !important;
  /* overflow:   hidden !important; */
  width:60%;
  height:2.5rem !important;
  padding-bottom: 0.2rem !important;
  padding-top: 0rem !important;
  padding-right:0.3rem !important;
  padding-left:0.3rem !important;
 
}


}






@media (min-width: 576px) {

  .imagesection{
    background-image:url(../../assets/WorldMap.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 45%; /* 4:3 aspect ratio (adjust as needed) */
    overflow: hidden;
    
  }
  .mobilephone{
      position: absolute;
      top: 0%;
      left: 26%;
      width: 295px;
      height: 100%;
      object-fit: cover; /* or "contain" depending on your needs */
      object-position: top center; /* Adjust as needed */
  }
  .introsection{
    width:100%;
    height:50dvh;
    padding-left:5% !important;
    padding-right:5% !important;
  }
  .producttab{
  background-color: #DCDCDC !important;
  border-radius:36px !important;
  /* overflow:   hidden !important; */
  width:35%;
  height:2.5rem !important;
  padding-bottom: 0.2rem !important;
  padding-top: 0rem !important;
  padding-right:0.3rem !important;
  padding-left:0.3rem !important;
 
}
.categorytext{
  font-size:0.85rem;
  color:#565656;
  padding-left:5% !important;
  padding-right:5% !important;
  font-family: 'jost';
}
.NewsContainer{
  width:100%;
  padding-left:5% !important;
  padding-right:5% !important;
}
.downloadContainerMain{
  margin-top:10rem;
  padding-left:4% !important;
  padding-right:4% !important;

}
.mainaccordion{
  width:75%;
}
.buttoncontainer{
  display:flex;
  flex-direction: row;
  gap:4px;
  justify-content:center;
  align-items:center;
}
.downloadappContainer{
  padding-left:6%;
  padding-right:6%;
}

}




@media (min-width: 768px) {
 

  .herosection {
    background: linear-gradient(180deg, #F36F2E 42.45%, #FFF 42.53%) !important; 
    height: auto;
    width: 100% !important;
    overflow-y: hidden !important;
    padding-left:7%;
    padding-right:7%;
  
  }
  
  .containersection{
    width: 100%;
    height: auto;
    background-color: #313434; /* Set your background color */
    clip-path: polygon( 0.053% 16.02%,0.053% 16.02%,0.063% 13.47%,0.217% 11.033%,0.504% 8.745%,0.913% 6.644%,1.43% 4.766%,2.045% 3.148%,2.746% 1.825%,3.52% 0.836%,4.356% 0.215%,5.242% 0%,94.739% 0%,94.739% 0%,95.627% 0.216%,96.465% 0.839%,97.24% 1.833%,97.941% 3.161%,98.557% 4.785%,99.074% 6.669%,99.482% 8.777%,99.767% 11.071%,99.92% 13.515%,99.926% 16.071%,98.038% 86.226%,98.038% 86.226%,97.916% 88.5%,97.679% 90.643%,97.339% 92.629%,96.905% 94.432%,96.385% 96.028%,95.791% 97.39%,95.13% 98.494%,94.414% 99.314%,93.651% 99.824%,92.85% 100%,7.047% 100%,7.047% 100%,6.244% 99.823%,5.48% 99.31%,4.762% 98.487%,4.1% 97.378%,3.505% 96.01%,2.986% 94.409%,2.552% 92.598%,2.212% 90.606%,1.978% 88.456%,1.857% 86.175%,0.053% 16.02% ) !important;
    
  }
  .textsection{
    
    padding-left:8% !important;
    padding-right:8% !important;
  }
  .imagesection{
    background-image:url(../../assets/WorldMap.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 20%; /* 4:3 aspect ratio (adjust as needed) */
    overflow: hidden;
    
  }
  .mobilephone{
      position: absolute;
      top: 15%;
      left: 0%;
      width: 200px;
      height: 100%;
      object-fit: cover; /* or "contain" depending on your needs */
      object-position: top center; /* Adjust as needed */
  }

  .downloadbtn{
    display:flex;
    flex-direction: row;
    margin-left:-1rem;
    gap:0.6rem;
    
  }
  .buttondownload{
    width:43% !important;
  }
  .herotitle{
    font-size:2.2rem;
  }

  .introsection{
    width:100%;
    height:auto;
    padding-left:8% !important;
    padding-right:8% !important;
  }
  .introinnersection{
    background:url(../../assets/mapbg.png), #F5F6F7;
    background-size: contain;
    padding-top:5rem;
    padding-left:10% !important;
    padding-right:10% !important;
    padding-bottom:5rem;
    border-radius:35px;
  }
  .introheader{
    font-size:3.0rem;
    font-family: "jost";
  }
  .introparagraph{
    font-size:0.85rem;
    width:65%;
    line-height:195%;
  }
  .readmorebtn{
    background-color: transparent !important;
    color: #313434 !important;
    border-color: #313434 !important;
    border-radius: 35px !important;
  }
  .producttab{
  background-color: #DCDCDC !important;
  border-radius:36px !important;
  /* overflow:   hidden !important; */
  width:30%;
  height:2.5rem !important;
  padding-bottom: 0.2rem !important;
  padding-top: 0rem !important;
  padding-right:0.3rem !important;
  padding-left:0.3rem !important;
 
}
.producttext{
  font-size:0.85rem;
  color:#565656;
  padding-left:15% !important;
  padding-right:15% !important;
  font-family: 'jost';
}
.shipperimage{
  margin-left: -2.5rem;
  display:inline !important;
}
.productsectionrow{
  padding-left:5% !important;
  padding-right:5% !important;
  margin-top: 3rem !important;
}
.FAQ {
  padding-left: 7% !important;
  padding-right: 7% !important;
  padding-top: 4rem;
  margin-top: 3rem;
}
.mainaccordion{
  width:75%;
}
.Contactus{
  margin-top:7rem;
  margin-bottom:5rem;
  background-color: #F9FAFB;
  padding-top: 5rem;
  padding-bottom:3rem;
  width:73% !important;
  border-radius:0.5rem;
}

.Bottomnav{
  margin-top:5rem;
  padding-left:7% !important;
  padding-right:7% !important;
  padding-top:3rem;
  padding-bottom:3rem;
}
.navlinkingclass{
  float:left !important;
  margin-bottom:2rem;
}
.newsletterhead{
  font-family: 'Jost', sans-serif;
  font-size:0.9rem;
  color: #5C5571;
  margin-top:0rem;
}
.newsletterformbody{
  width:100%;
  float:left;
  margin-top:2rem;
}
.categorycontainer{
  width:100%;
  margin-top:7rem;
  padding-left:7% !important;
  padding-right:7% !important;
}
.categorytext{
  font-size:0.85rem;
  color:#565656;
  padding-left:10% !important;
  padding-right:10% !important;
  font-family: 'jost';
}
.featurescontainer{
  width:100%;
  padding-left:7%;
  padding-right:7%;
}
.NewsContainer{
  width:100%;
  padding-left:7% !important;
  padding-right:7% !important;
}
.downloadContainerMain{
  margin-top:10rem;
  padding-left:8% !important;
  padding-right:8% !important;

}
.downloadappContainer{
  padding-left:26%;
  padding-right:26%;
}
.contacttext{
  width:72%;
}
.waitlist-btn{
  width:auto !important;
}
.animated-category{
  width:100% !important;

}
.services-container{
  display:flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  gap:4rem;
  margin-top:9rem
}
.service-title{
  text-align: start;
  justify-content: start;
  align-items: start;
  width:50%;
}
.figurebase{
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}
.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex !important;
  flex-wrap: nowrap !important;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  width: 40% !important; /* Set the width */
  margin: 0 auto; /* Center the nav */
  overflow-x: auto !important; /* Allow scrolling */
  overflow-y: hidden !important;
  scrollbar-width: none; /* For Firefox */
}

.nav::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}


.downloadtab {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex !important;
  flex-wrap: nowrap !important;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  width: 100% !important; /* Set the width */
  margin: 0 auto; /* Center the nav */
  overflow-x: auto !important; /* Allow scrolling */
  overflow-y: hidden !important;
  scrollbar-width: none; /* For Firefox */
}
.services-container{
  padding-left:7% !important;
  padding-right:7% !important;
}




  
}







@media (min-width: 820px) {
  .herosection {
    background: linear-gradient(180deg, #F36F2E 42.45%, #FFF 42.53%) !important; 
    height: auto;
    width: 100% !important;
    overflow-y: hidden !important;
    padding-left:6% !important;
    padding-right:6% !important;
    
  }

  .imagesection{
    background-image:url(../../assets/WorldMap.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 20%; /* 4:3 aspect ratio (adjust as needed) */
    overflow: hidden;
    
  }
  .mobilephone{
      position: absolute;
      top: 15%;
      left: 0%;
      width: 200px;
      height: 100%;
      object-fit: cover; /* or "contain" depending on your needs */
      object-position: top center; /* Adjust as needed */
  }

  .textsection{
   
    padding-left:7% !important;
    padding-right:7% !important;
  }
  .herotitle{
    font-size:2.2rem;
    
  }
  .downloadbtn{
    display:flex;
    margin-left:0rem;
    gap:0.6rem;
   
  }
  .introinnersection{
    background:url(../../assets/mapbg.png), #F5F6F7;
    background-size: contain;
    padding-top:5rem;
    padding-left:15% !important;
    padding-right:15% !important;
    padding-bottom:5rem;
    border-radius:35px;
  }
  
  .buttondownload{
    width:43% !important;
  }
   .producttab{
  background-color: #DCDCDC !important;
  border-radius:36px !important;
  /* overflow:   hidden !important; */
  width:25%;
  height:2.5rem !important;
  padding-bottom: 0.2rem !important;
  padding-top: 0rem !important;
  padding-right:0.3rem !important;
  padding-left:0.3rem !important;
 
}
.productsectionrow{
  padding-left:10% !important;
  padding-right:10% !important;
}
.Bottomnav{
  margin-top:5rem;
  padding-left:10% !important;
  padding-right:10% !important;
  padding-top:3rem;
  padding-bottom:3rem;
}
.categorycontainer{
  width:100%;
  margin-top:7rem;
  padding-left:8% !important;
  padding-right:8% !important;
}
.categorytext{
  font-size:0.85rem;
  color:#565656;
  padding-left:15% !important;
  padding-right:15% !important;
  font-family: 'jost';
}
.featurescontainer{
  width:100%;
  padding-left:9% !important;
  padding-right:9% !important;
}
.NewsContainer{
  width:100%;
  padding-left:9% !important;
  padding-right:9% !important;
}
.downloadContainerMain{
  margin-top:10rem;
  padding-left:9% !important;
  padding-right:9% !important;

}
.mainaccordion{
  width:60%;
}
.Contactus{
  margin-top:7rem;
  margin-bottom:5rem;
  background-color: #F9FAFB;
  padding-top: 5rem;
  padding-bottom:3rem;
  width:63% !important;
  border-radius:0.5rem;
}

  
}





@media (min-width: 992px) {
  .herosection {
    background: linear-gradient(180deg, #F36F2E 42.45%, #FFF 42.53%) !important; 
    height: auto;
    width: 100% !important;
    overflow-y: hidden !important;
    
    padding-left:8% !important;
    padding-right:8% !important;
    
  }
 
  .containersection{
    width: 100%;
    height: auto;
    background-color: #313434; /* Set your background color */
   
  }
  .imagesection{
    background-image:url(../../assets/WorldMap.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 20%; /* 4:3 aspect ratio (adjust as needed) */
    overflow: hidden;
    
  }
  .mobilephone{
      position: absolute;
      top: 10%;
      left: 10%;
      width: 250px;
      height: 100%;
      object-fit: cover; /* or "contain" depending on your needs */
      object-position: top center; /* Adjust as needed */
  }

  .textsection{
   
    padding-left:8% !important;
    padding-right:8% !important;
  }
  .herotitle{
    font-size:2.4rem;
    
  }
  .buttondownload{
    width:40% !important;
  }
   .producttab{
  background-color: #DCDCDC !important;
  border-radius:36px !important;
  /* overflow:   hidden !important; */
  width:20%;
  height:2.5rem !important;
  padding-bottom: 0.2rem !important;
  padding-top: 0rem !important;
  padding-right:0.3rem !important;
  padding-left:0.3rem !important;
 
}
.producttext{
  font-size:0.85rem;
  color:#565656;
  padding-left:25% !important;
  padding-right:25% !important;
  font-family: 'jost';
}
.productsectionrow{
  padding-left:10% !important;
  padding-right:10% !important;
}
.Bottomnav{
  margin-top:5rem;
  padding-left:10% !important;
  padding-right:10% !important;
  padding-top:3rem;
  padding-bottom:3rem;
}
.navlinkingclass{
  float:right !important;
  margin-bottom:2rem;
}
.categorycontainer{
  width:100%;
  margin-top:7rem;
  padding-left:10% !important;
  padding-right:10% !important;
}
.NewsContainer{
  width:100%;
  padding-left:10%;
  padding-right:10%;
}
.mainaccordion{
  width:55%;
}
.Contactus{
  margin-top:7rem;
  margin-bottom:5rem;
  background-color: #F9FAFB;
  padding-top: 5rem;
  padding-bottom:3rem;
  width:63% !important;
  border-radius:0.5rem;
}


  
}





@media (min-width: 1200px) {
  .herosection {
    background: linear-gradient(180deg, #F36F2E 42.45%, #FFF 42.53%) !important; 
    height: auto;
    width: 100% !important;
    overflow-y: hidden !important;
  
    padding-left:8% !important;
    padding-right:8% !important;
   
  }
  .buttondownload{
    width:33% !important;
  }

  .imagesection{
    background-image:url(../../assets/WorldMap.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 20%; /* 4:3 aspect ratio (adjust as needed) */
    overflow: hidden;
    
  }
  .mobilephone{
      position: absolute;
      top: 10%;
      left: 10%;
      width: 250px;
      height: 100%;
      object-fit: cover; /* or "contain" depending on your needs */
      object-position: top center; /* Adjust as needed */
  }
   .producttab{
  background-color: #DCDCDC !important;
  border-radius:36px !important;
  /* overflow:   hidden !important; */
  width:17%;
  height:2.5rem !important;
  padding-bottom: 0.2rem !important;
  padding-top: 0rem !important;
  padding-right:0.3rem !important;
  padding-left:0.3rem !important;
 
}
.productsectionrow{
  padding-left:9% !important;
  padding-right:9% !important;
}
.Bottomnav{
  margin-top:5rem;
  padding-left:10% !important;
  padding-right:10% !important;
  padding-top:3rem;
  padding-bottom:3rem;
}
.featurescontainer{
  width:100%;
  padding-left:9% !important;
  padding-right:9% !important;
}
.NewsContainer{
  width:100%;
  padding-left:9% !important;
  padding-right:9% !important;
}
.mainaccordion{
  width:55%;
}
.Contactus{
  margin-top:7rem;
  margin-bottom:5rem;
  background-color: #F9FAFB;
  padding-top: 5rem;
  padding-bottom:3rem;
  width:60% !important;
  border-radius:0.5rem;
}
.newsletterformbody{
  width:100%;
  float:left;
  margin-top:2rem;
}
}



@media (min-width: 1400px) {
  .herosection {
    background: linear-gradient(180deg, #F36F2E 42.45%, #FFF 42.53%) !important; 
    height: auto;
    width: 100% !important;
    overflow-y: hidden !important;
 
    padding-left:14% !important;
    padding-right:14% !important;
   
  }

  .imagesection{
    background-image:url(../../assets/WorldMap.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 20%; /* 4:3 aspect ratio (adjust as needed) */
    overflow: hidden;
    
  }
  .mobilephone{
      position: absolute;
      top: 10%;
      left: 10%;
      width: 250px;
      height: 100%;
      object-fit: cover; /* or "contain" depending on your needs */
      object-position: top center; /* Adjust as needed */
  }
  .introsection{
    width:100%;
    height:auto;
    padding-left:14% !important;
    padding-right:14% !important;
  }
   .producttab{
  background-color: #DCDCDC !important;
  border-radius:36px !important;
  /* overflow:   hidden !important; */
  width:16%;
  height:2.5rem !important;
  padding-bottom: 0.2rem !important;
  padding-top: 0rem !important;
  padding-right:0.3rem !important;
  padding-left:0.3rem !important;
 
}
.productsectionrow{
  padding-left:14% !important;
  padding-right:14% !important;
}
.Bottomnav{
  margin-top:5rem;
  padding-left:14% !important;
  padding-right:14% !important;
  padding-top:3rem;
  padding-bottom:3rem;
}
.categorycontainer{
  width:100%;
  margin-top:7rem;
  padding-left:14% !important;
  padding-right:14% !important;
}
.featurescontainer{
  width:100%;
  padding-left:14% !important;
  padding-right:14% !important;
}
.NewsContainer{
  width:100%;
  padding-left:14% !important;
  padding-right:14% !important;
}
.downloadContainerMain{
  margin-top:10rem;
  padding-left:16% !important;
  padding-right:16% !important;

}

}


@media (min-width: 1600px) {
  .herosection {
    background: linear-gradient(180deg, #F36F2E 42.45%, #FFF 42.53%) !important; 
    height: auto;
    width: 100% !important;
    overflow-y: hidden !important;

    padding-left:20% !important;
    padding-right:20% !important;
  
  }
  .introsection{
    width:100%;
    height:auto;
    padding-left:20% !important;
    padding-right:20% !important;
  }
  .productsectionrow{
    padding-left:19% !important;
    padding-right:19% !important;
  }
  .Bottomnav{
    margin-top:5rem;
    padding-left:20% !important;
    padding-right:20% !important;
    padding-top:3rem;
    padding-bottom:3rem;
  }
  .categorycontainer{
    width:100%;
    margin-top:7rem;
    padding-left:20% !important;
    padding-right:20% !important;
  }
  .featurescontainer{
    width:100%;
    padding-left:20% !important;
    padding-right:20% !important;
  }
  .NewsContainer{
    width:100%;
    padding-left:20% !important;
    padding-right:20% !important;
  }
  .downloadContainerMain{
    margin-top:10rem;
    padding-left:20% !important;
    padding-right:20% !important;
  
  }
  .Contactus{
    margin-top:7rem;
    margin-bottom:5rem;
    background-color: #F9FAFB;
    padding-top: 5rem;
    padding-bottom:3rem;
    width:45% !important;
    border-radius:0.5rem;
  }

}
