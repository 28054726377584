@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&family=Redressed&display=swap");

.privacy {
	/* background: linear-gradient(180deg, #F36F2E 42.45%, #FFF 42.53%) !important;  */
	height: auto;
	margin-bottom: 7.6rem;
	width: 80% !important;
	overflow-y: hidden !important;
	padding-left: 3% !important;
	padding-right: 3% !important;

}

.headings{
  padding-left:2% !important;
  padding-right:2% !important;
}

.privacy-content {
	font-family: "jost";
	font-size: 0.93rem;
	line-height: 29px;
	color: #333333;
}



@media (min-width: 576px) {
  .headings{
    padding-left:3% !important;
    padding-right:3% !important;
  }
}


@media (min-width: 768px) {
  .headings{
    padding-left:6% !important;
    padding-right:6% !important;
  } 
}

@media (min-width: 820px) {
  .headings{
    padding-left:5% !important;
    padding-right:5% !important;
  }
}



@media (min-width: 992px) {

  .headings{
    padding-left:7% !important;
    padding-right:7% !important;
  } 
}





@media (min-width: 1200px) {
  .headings{
    padding-left:7% !important;
    padding-right:7% !important;
  } 
}



@media (min-width: 1400px) {
  .headings{
    padding-left:13% !important;
    padding-right:13% !important;
  } 
}


@media (min-width: 1600px) {
  .headings{
    padding-left:19% !important;
    padding-right:19% !important;
  }
}