/* customDatePicker.css */

.react-datepicker {
    font-size: 0.8rem !important; /* Adjust font size */
  }
  
  .react-datepicker__input-container input {
    height: 35px; /* Adjust input height */
    width:8rem;
    padding: 0.375rem 0.75rem; /* Adjust padding */
    justify-content: center;
    align-items: center;
    font-size: 0.8rem !important;
    box-shadow: 0px 0px 5px 0 #b2b3b3 !important;
    border: 1px solid #b8b8b8 !important;
    border-radius: 5px !important;
  }
  
  .react-datepicker__current-month,
  .react-datepicker__day-name,
  .react-datepicker__day {
    font-size: 0.7rem !important; /* Adjust day and month font size */
  }
  
  .react-datepicker__header {
    padding-top: 0.5rem; /* Adjust header padding */
    padding-bottom: 0.5rem; /* Adjust header padding */
  }
  /* customDatePicker.css */

.react-datepicker__input-container input::placeholder {
    font-size: 0.9rem !important; /* Adjust placeholder font size */
    margin-top:-3rem !important;
  }
  .react-datepicker__input-container input:focus {
    outline: none; /* Remove outline on focus */
  }
  .react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #F36F2E;
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    margin-top:1px !important;
    vertical-align: middle !important;
    content: "×";
}
.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    line-height: initial;
    margin-right: 7rem !important;
}

/* customDatePicker.css */


.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: #F36F2E !important;
    color: #fff;
}

  @media (min-width: 768px) {
    .react-datepicker-popper {
        left: none !important;
        right: none !important;
      }
      
      .react-datepicker {
        text-align: center !important;
      }  
      .react-datepicker {
        font-family: "Helvetica Neue", helvetica, arial, sans-serif;
        font-size: 0.8rem;
        background-color: #fff;
        color: #000;
        border: 1px solid #aeaeae;
        border-radius: 0.3rem;
        display: inline-block;
        position: relative;
        line-height: initial;
        margin-right: 0rem !important;
    }
    
  }
   
  