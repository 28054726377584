@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&family=Redressed&display=swap");

.herosection {
  background: linear-gradient(180deg, #F36F2E 42.45%, #FFF 42.53%) !important; 
  height: auto;
  margin-bottom: 7.6rem;
  width: 100% !important;
  overflow-y: hidden !important;
  padding-left:3% !important;
  padding-right:3% !important;
 
}

.containersectionabt{
  width: 100%;
  height: auto;
  margin-top:2rem;
  padding-top:5rem;
  padding-left:1rem !important;
  padding-right:1rem !important;
  padding-bottom:2rem;
  background-color:  #313434 !important; /* Set your background color */
  background-image:url(../../assets/WorldMap2.svg);
  background-size: contain;
  border-radius: 18.93px;
 
}
.otherSection{
  padding-left:5% !important;
  padding-right:5% !important;
}
.otherSectionText{
  padding-top:0rem;
}
.otherSectionTextHead{
  font-size:2.5rem;
}
.BetaTesterContainer{
  background-color: #FFFFFF;
  padding-top:1rem;
  padding-bottom:4rem;
  padding-left:5% !important;
  padding-right:5% !important;
}
.introContainerHeader{
  width:100%;
  font-size:2.2rem;
  color: #F36F2E;
}
.introContainerbody{
  padding-top:4rem;
  overflow-x: hidden;
  margin-top:1rem;
  justify-content: center;
  align-items: center;
  margin-bottom:2rem !important;


}

.firstmockup{
  width: 60%;
  float: none !important;
  
}
.secondmockup{
  width: 60%;
  float: none !important;
  
}
.firstStepContent{
  width:100%;
  margin-top:2rem;
}
.secondStepContent{
  width:100%;
  margin-top:2rem;
  float: none;
}

.firstprocess{
  position: absolute;
  top: 18%;
  left: 55%;
  transform: translate(-50%, -50%);
  display: none;
}
.secondprocess{
  position: absolute;
  top: 32%;
  left: 25%;
  transform: translate(-50%, -50%);
  transform: scaleX(-1);
  display: none;
  
}
.thirdprocess{
  position: absolute;
  top: 59%;
  left: 56%;
  transform: translate(-50%, -50%);
  display: none;
}
.fourthprocess{
  position: absolute;
  top: 74%;
  left: 25%;
  transform: translate(-50%, -50%);
  transform: scaleX(-1);
  display: none;
  
}

.stepheader{
  font-weight: bold;
  color: #000000;
  font-size:1.2rem;
}
.stepparagraph{
  font-weight: 400;
  color:#5F6D7E;
  font-size:0.95rem;
}
.downloadtabx1{
  background-color: #DCDCDC !important;
  border-radius:36px !important;
  /* overflow:   hidden !important; */
  width:20%;
  height:2.5rem !important;
  padding-bottom: 0.2rem !important;
  padding-top: 0rem !important;
  padding-right:0.3rem !important;
  padding-left:0.3rem !important;
 
}
.paragraphtext{
  font-size:0.9rem;
  width:100%;
}
.otherImage2{
  display: none !important;
}






@media (min-width: 321px) {
  .herosection {
    background: linear-gradient(180deg, #F36F2E 42.45%, #FFF 42.53%) !important; 
    height: auto;
    width: 100% !important;
    overflow-y: hidden !important;
    padding-left:4% !important;
    padding-right:4% !important;
   
  }
  
  .containersectionabt{
    width: 100%;
    height: auto;
    background-color:  #313434 !important; /* Set your background color */
    background-image:url(../../assets/WorldMap2.svg);
    background-size: cover;
   
  }
  .downloadtabx1{
    background-color: #DCDCDC !important;
    border-radius:36px !important;
    /* overflow:   hidden !important; */
    width:70%;
    height:2.5rem !important;
    padding-bottom: 0.2rem !important;
    padding-top: 0rem !important;
    padding-right:0.3rem !important;
    padding-left:0.3rem !important;
   
  }
  
}






@media (min-width: 576px) {

  .otherSection{
    padding-left:5% !important;
    padding-right:5% !important;
  }
  .downloadtabx1{
    background-color: #DCDCDC !important;
    border-radius:36px !important;
    /* overflow:   hidden !important; */
    width:40%;
    height:2.5rem !important;
    padding-bottom: 0.2rem !important;
    padding-top: 0rem !important;
    padding-right:0.3rem !important;
    padding-left:0.3rem !important;
   
  }

}




@media (min-width: 768px) {
 

  .herosection {
    background: linear-gradient(180deg, #F36F2E 42.45%, #FFF 42.53%) !important; 
    height: auto;
    width: 100% !important;
    overflow-y: hidden !important;
    padding-left:7%;
    padding-right:7%;
  
  }
  
  .containersectionabt{
    width: 100%;
    height: auto;
    background-color:  #313434 !important; /* Set your background color */
    background-image:url(../../assets/WorldMap2.svg);
    padding-top:10rem;
    padding-left:4rem !important;
    padding-right:4rem !important;
    padding-bottom:2rem;
    background-size: cover;
    border-radius: 18.93px;
  }
  .otherSection{
    padding-left:7% !important;
    padding-right:7% !important;
  }
  .otherSectionText{
    padding-top:0rem;
  }
  .otherSectionTextHead{
    font-size:2.4rem;
  }
  .downloadtabx1{
    background-color: #DCDCDC !important;
    border-radius:36px !important;
    /* overflow:   hidden !important; */
    width:40%;
    height:2.5rem !important;
    padding-bottom: 0.2rem !important;
    padding-top: 0rem !important;
    padding-right:0.3rem !important;
    padding-left:0.3rem !important;
   
  }
 
  .paragraphtext{
    font-size:0.9rem;
    width:70%;
  }
  .introContainerbody{
    padding-top:4rem;
    overflow-x: hidden;
    margin-top:1rem;
    justify-content: center;
    align-items: center;
    margin-bottom:5.5rem !important;
  }
  .otherImage2{
    display: block !important;
  }
  
  
  
 
}







@media (min-width: 820px) {
  .herosection {
    background: linear-gradient(180deg, #F36F2E 42.45%, #FFF 42.53%) !important; 
    height: auto;
    width: 100% !important;
    overflow-y: hidden !important;
    padding-left:6% !important;
    padding-right:6% !important;
    
  }
  .otherSection{
    padding-left:6% !important;
    padding-right:6% !important;
  }
  .otherSectionText{
    padding-top:1rem;
  }
  .otherSectionTextHead{
    font-size:2.5rem;
  }
  .BetaTesterContainer{
    background-color: #FFFFFF;
    padding-top:1rem;
    padding-bottom:4rem;
    padding-left:1% !important;
    padding-right:1% !important;
  }
  .introContainerbody{
    padding-top:4rem;
    overflow-x: hidden;
    margin-top:1rem;
    justify-content: center;
    align-items: center;
    margin-bottom:5.5rem !important;
  }

  
  
}






@media (min-width: 992px) {
  .herosection {
    background: linear-gradient(180deg, #F36F2E 42.45%, #FFF 42.53%) !important; 
    height: auto;
    width: 100% !important;
    overflow-y: hidden !important;
    
    padding-left:8% !important;
    padding-right:8% !important;
    
  }
 
  .containersectionabt{
    width: 100%;
    height: auto;
    background-color:  #313434 !important; /* Set your background color */
    background-image:url(../../assets/WorldMap2.svg);
    background-size: contain;
  }
  .otherSection{
    padding-left:8% !important;
    padding-right:8% !important;
  }
  .otherSectionText{
    padding-top:2rem;
  }
  .otherSectionTextHead{
    font-size:2.7rem;
  }
  .BetaTesterContainer{
    background-color: #FFFFFF;
    padding-top:1rem;
    padding-bottom:4rem;
    padding-left:8% !important;
    padding-right:8% !important;
  }
  .firstprocess{
    position: absolute;
    top: 18%;
    left: 55%;
    transform: translate(-50%, -50%);
    display: inline;
  }
  .secondprocess{
    position: absolute;
    top: 32%;
    left: 25%;
    transform: translate(-50%, -50%);
    transform: scaleX(-1);
    display: inline;
    
  }
  .thirdprocess{
    position: absolute;
    top: 59%;
    left: 56%;
    transform: translate(-50%, -50%);
    display: inline;
  }
  .fourthprocess{
    position: absolute;
    top: 74%;
    left: 25%;
    transform: translate(-50%, -50%);
    transform: scaleX(-1);
    display: inline;
    
  }
  .introContainerbody{
    padding-top:4rem;
    overflow-x: hidden;
    margin-top:1rem;
    justify-content: center;
    align-items: center;
    margin-bottom:2rem !important;
  }
 


  
}





@media (min-width: 1200px) {
  .herosection {
    background: linear-gradient(180deg, #F36F2E 42.45%, #FFF 42.53%) !important; 
    height: auto;
    width: 100% !important;
    overflow-y: hidden !important;
  
    padding-left:8% !important;
    padding-right:8% !important;
   
  }
  .otherSection{
    padding-left:8% !important;
    padding-right:8% !important;
  }
  .otherSectionText{
    padding-top:5rem;
  }
  .otherSectionTextHead{
    font-size:2.7rem;
  }
  .introContainerbody{
    padding-top:4rem;
    overflow-x: hidden;
    margin-top:1rem;
    justify-content: center;
    align-items: center;
    margin-bottom:2.5rem !important;
  }
 
}



@media (min-width: 1400px) {
  .herosection {
    background: linear-gradient(180deg, #F36F2E 42.45%, #FFF 42.53%) !important; 
    height: auto;
    width: 100% !important;
    overflow-y: hidden !important;
 
    padding-left:14% !important;
    padding-right:14% !important;
   
  }
  .otherSection{
    padding-left:14% !important;
    padding-right:14% !important;
  }
  .otherSectionText{
    padding-top:5rem;
  }
  .otherSectionTextHead{
    font-size:2.7rem;
  }
  .BetaTesterContainer{
    background-color: #FFFFFF;
    padding-top:1rem;
    padding-bottom:4rem;
    padding-left:12% !important;
    padding-right:12% !important;
  }

 
}


@media (min-width: 1600px) {
  .herosection {
    background: linear-gradient(180deg, #F36F2E 42.45%, #FFF 42.53%) !important; 
    height: auto;
    width: 100% !important;
    overflow-y: hidden !important;

    padding-left:20% !important;
    padding-right:20% !important;
  
  }
  .otherSection{
    padding-left:20% !important;
    padding-right:20% !important;
  }
  .BetaTesterContainer{
    background-color: #FFFFFF;
    padding-top:1rem;
    padding-bottom:4rem;
    padding-left:20% !important;
    padding-right:20% !important;
  }

}
