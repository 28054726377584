.contactus{
    height:100vh;
}
.formside{
    background: url(../../assets/formsidebg.png);
    background-size: cover !important;
    background-repeat:no-repeat;
   height: auto;
    min-height:100dvh;
    width:100%;
    bottom:0;
    margin-top:5rem !important;
    padding-top: 2rem;
    padding-left:3%;
    padding-right:3%;
    display: inline !important;
    
}
.navlogodisplay{
    display:inline !important;
    margin-bottom:2rem;
}
.formmain{
    padding-top: 2rem;
    padding-left:3%;
    padding-right:3%;
    
}
.formcontrol {
    border-radius: 4.42px;
    border: 0.9px solid var(--input-field-stoke, #DDD) !important;
    background: #EDEDED !important;
    box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.02);
}


.formcontrol::placeholder {
    font-size: 0.85rem;
  }

  .formlabel{
    font-size:0.85rem;
  }

  .formcontrol:focus {
    outline: none;
    box-shadow: 0 0 0.2px 0.2px rgba(80, 80, 80, 0.2) !important;
  }
  .logoss{
    display: none !important;
}
.otherContent{
    margin-top: 5vh !important;
    display: flex;
    flex-direction:column;
    gap: 1rem;
}

.selectusertype-a{
    width:80%;
    background-color: #DCDCDC !important;
    border-radius:36px !important;
    height:2.5rem !important;
    padding-bottom: 0.2rem !important;
    padding-top: 0rem !important;
    padding-right:0.3rem !important;
    padding-left:0.3rem !important;
  }
  .react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 4px;
    margin-left: 0;
    background: #EDEDED !important;
    border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    width:100% !important;
    height: 35px;
    outline: none;
}
 .form-control:focus {
    outline: none !important;
    box-shadow: 0 0 0.2px 0.2px rgba(80, 80, 80, 0.2) !important;
  }


@media (min-width: 576px) { 

 }


@media (min-width: 768px) { 
    .navlogodisplay{
        display:none !important;
    }
    .formmain{
        padding-top: 2rem;
        padding-left:3% !important;
        padding-right:3% !important;
        
    }
    .formside{
        background: url(../../assets/formsidebg.png);
        background-size: cover !important;
       
        margin-top:0rem !important;
        padding-left:7%;
        padding-right:7%;
        display: inline !important;
        
    }
    .otherContent{
        margin-top: 50vh !important;
        display: flex;
        flex-direction:column;
        gap: 1rem;
    }
    .logoss{
        display: inline !important;
    }
    

 }

 @media (min-width: 850px) { 
    .formmain{
        padding-top: 2rem;
        padding-left:3% !important;
        padding-right:3% !important;
        
    }
 }


@media (min-width: 992px) { 
    .formmain{
        padding-top: 2rem;
        padding-left:3% !important;
        padding-right:3% !important;
        
    }
 }


@media (min-width: 1200px) { 
    .formmain{
        padding-top: 2rem;
        padding-left:3%;
        padding-right:3%;
        
    }
    .formside{
        padding-left:8%;
        padding-right:8%;
        
    }

 }


@media (min-width: 1400px) { 
    .formmain{
        padding-top: 2rem;
        padding-left:3%;
        padding-right:3%;
        
    }
    .formside{
        padding-left:14%;
        padding-right:14%;
        
    }

 }