.dataDeletionRequest {
    padding: 2rem;
    max-width: 700px;
    margin: 0 auto;
    font-family: "jost";
  }
  
  .dataDeletionRequest h2 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  
  .dataDeletionRequest p {
    line-height: 1.5;
  }
  
  .keyPoints {
    list-style: disc;
    padding-left: 1rem;
  }
  
  .deletionMethods {
    margin-top: 0.5rem;
    padding-left: 1.5rem;
    list-style: disc;
  }
  
  .redacted {
    color: #ccc;
  }
  